.rowWarning {
  background-color: #fcd76a;
}

.rowDanger {
  background-color: #fcd76a;
  background: #fcd76a;
}

.siteFont {
  font-size: 1.1em;
}
